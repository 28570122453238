/* eslint-disable @typescript-eslint/no-shadow -- typescript gets confused. It's ok */
import { Inject, Injectable } from '@angular/core';
import { ActionReducerMap, combineReducers } from '@ngrx/store';
import { SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { LoggerService } from '@safarilaw-webapp/shared/logging';
import { DROPDOWN_REDUCER, ISafariObjectState, REDUCER, SafariReduxApiObject, SafariReduxDropdownObject } from '@safarilaw-webapp/shared/redux';
import { AttachmentLink } from '@safarilaw-webapp/shared/common-objects-models';
import { Acknowledgment } from '../acknowledgment/models/app/acknowledgment';
import { MyAuth } from '../auth/models/my-auth';
import { DeliveryPortal } from '../delivery-portal/models/app/delivery-portal';
import { IntakePortalForm } from '../intake-portal-form/models/app/intake-portal-form';
import { IntakePortal } from '../intake-portal/models/app/intake-portal';
import { MyIntakePortal } from '../my-intake-portal/models/app/my-intake-portal';
import { OrganizationSearch } from '../ogranization-search/models/app/organization-search';
import { Profile } from '../profile/models/app/profile';
import { Submission } from '../submission/models/app/submission';
import { SubmissionBatch } from '../submission-batch/models/app/submission-batch';
import * as fileUpload from './file-transfer';
import {
  ACKNOWLEDGE_INSTRUCTIONS_REDUX_OBJECT_TOKEN,
  CONFIRM_RECEIPT_REDUX_OBJECT_TOKEN,
  DELIVERY_PORTAL_REDUX_OBJECT_TOKEN,
  DROPDOWN_REDUX_OBJECT_TOKEN,
  GENERATE_SUBMISSION_TEMPLINK_REDUX_OBJECT_TOKEN,
  GENERATE_TEMPLINK_REDUX_OBJECT_TOKEN,
  GENERATE_TEMPZIP_REDUX_OBJECT_TOKEN,
  INTAKE_PORTAL_FORM_REDUX_OBJECT_TOKEN,
  INTAKE_PORTAL_REDUX_OBJECT_TOKEN,
  INTAKE_PORTAL_URL_REDUX_OBJECT_TOKEN,
  MY_AUTH_REDUX_OBJECT_TOKEN,
  MY_INTAKE_PORTAL_REDUX_OBJECT_TOKEN,
  MY_PROFILE_REDUX_OBJECT_TOKEN,
  ORGANIZATION_SEARCH_REDUX_OBJECT_TOKEN,
  SUBMISSION_BATCH_REDUX_OBJECT_TOKEN,
  SUBMISSION_REDUX_OBJECT_TOKEN
} from './injectable-object-tokens';
import { IRmsState, IRmsStateDropdown } from './state.interface';
@Injectable({
  providedIn: 'root'
})
export class RmsDataAccessObject {
  constructor(
    @Inject(DELIVERY_PORTAL_REDUX_OBJECT_TOKEN) public DeliveryPortal: SafariReduxApiObject<ISafariObjectState<DeliveryPortal>, DeliveryPortal>,
    @Inject(INTAKE_PORTAL_REDUX_OBJECT_TOKEN) public IntakePortal: SafariReduxApiObject<ISafariObjectState<IntakePortal>, IntakePortal>,
    @Inject(INTAKE_PORTAL_URL_REDUX_OBJECT_TOKEN) public IntakePortalUrl: SafariReduxApiObject<ISafariObjectState<IntakePortal>, IntakePortal>,
    @Inject(ORGANIZATION_SEARCH_REDUX_OBJECT_TOKEN) public OrganizationSearch: SafariReduxApiObject<ISafariObjectState<OrganizationSearch>, OrganizationSearch>,
    @Inject(INTAKE_PORTAL_FORM_REDUX_OBJECT_TOKEN) public IntakeForm: SafariReduxApiObject<ISafariObjectState<IntakePortalForm>, IntakePortalForm>,
    @Inject(SUBMISSION_REDUX_OBJECT_TOKEN) public Submission: SafariReduxApiObject<ISafariObjectState<Submission>, Submission>,
    @Inject(MY_AUTH_REDUX_OBJECT_TOKEN) public MyAuth: SafariReduxApiObject<ISafariObjectState<MyAuth>, MyAuth>,
    @Inject(GENERATE_TEMPLINK_REDUX_OBJECT_TOKEN) public AttachmentLink: SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>,
    @Inject(GENERATE_SUBMISSION_TEMPLINK_REDUX_OBJECT_TOKEN) public SubmissionAttachmentLink: SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>,
    @Inject(GENERATE_TEMPZIP_REDUX_OBJECT_TOKEN) public AttachmentZip: SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>,
    @Inject(CONFIRM_RECEIPT_REDUX_OBJECT_TOKEN) public ConfirmReceipt: SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>,
    @Inject(ACKNOWLEDGE_INSTRUCTIONS_REDUX_OBJECT_TOKEN) public Acknowledgment: SafariReduxApiObject<ISafariObjectState<Acknowledgment>, Acknowledgment>,
    @Inject(MY_PROFILE_REDUX_OBJECT_TOKEN) public Profile: SafariReduxApiObject<ISafariObjectState<Profile>, Profile>,
    @Inject(MY_INTAKE_PORTAL_REDUX_OBJECT_TOKEN) public MyIntakePortal: SafariReduxApiObject<ISafariObjectState<MyIntakePortal>, MyIntakePortal>,
    // "GLOBAL" OBJECTS
    @Inject(DROPDOWN_REDUX_OBJECT_TOKEN) public Dropdown: SafariReduxDropdownObject<IRmsStateDropdown>,
    @Inject(SUBMISSION_BATCH_REDUX_OBJECT_TOKEN) public SubmissionBatch: SafariReduxApiObject<ISafariObjectState<SubmissionBatch>, SubmissionBatch>

    /*** BEWARE OF DOG - DO NOT DELETE OR PUT ANYTHING ELSE BELOW THIS (1) ***/
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class ReducerService {
  constructor(
    public RmsObject: RmsDataAccessObject,
    private _logger: LoggerService
  ) {}
  get reducers(): ActionReducerMap<IRmsState> {
    return {
      intakePortalCombinedState: combineReducers({
        intakePortalState: REDUCER(this.RmsObject.IntakePortal, this._logger),
        intakePortalUrlState: REDUCER(this.RmsObject.IntakePortalUrl, this._logger)
      }),
      intakeFormCombinedState: combineReducers({
        intakeFormState: REDUCER(this.RmsObject.IntakeForm, this._logger)
      }),
      submissionCombinedState: combineReducers({
        submissionState: REDUCER(this.RmsObject.Submission, this._logger)
      }),
      deliveryPortalCombinedState: combineReducers({
        deliveryPortalState: REDUCER(this.RmsObject.DeliveryPortal, this._logger)
      }),
      attachmentLinkCombinedState: combineReducers({
        attachmentLinkState: REDUCER(this.RmsObject.AttachmentLink, this._logger)
      }),
      organizationCombinedState: combineReducers({
        searchState: REDUCER(this.RmsObject.OrganizationSearch, this._logger)
      }),
      dropdownCombinedState: combineReducers({
        dropdownState: DROPDOWN_REDUCER(this.RmsObject.Dropdown)
      }),
      myCombinedState: combineReducers({
        profileState: REDUCER(this.RmsObject.Profile, this._logger),
        intakePortalState: REDUCER(this.RmsObject.MyIntakePortal, this._logger)
      }),
      fileCombinedState: fileUpload.combinedReducers,
      submissionBatchCombinedState: combineReducers({
        submissionBatchState: REDUCER(this.RmsObject.SubmissionBatch, this._logger)
      })
      /*** BEWARE OF DOG - DO NOT DELETE OR PUT ANYTHING ELSE BELOW THIS (2) ***/
    };
  }
}
